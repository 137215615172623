import React, { useState } from "react";
import { navigate } from "gatsby";
import useForm from "react-hook-form";
import axios from "axios";
import Confetti from "react-dom-confetti";
import { Input, Check, Loader } from "../components";

const WingsForm = ({ locales }) => {
  const { register, errors, handleSubmit, triggerValidation } = useForm({
    mode: "onChange",
  });
  const config = {
    angle: 90,
    spread: 100,
    startVelocity: 60,
    elementCount: 150,
    dragFriction: 0.1,
    duration: 2000,
    stagger: 0,
    width: "7px",
    height: "10px",
    colors: [
      "#E68F17",
      "#FAB005",
      "#FA5252",
      "#E64980",
      "#BE4BDB",
      "#0B7285",
      "#15AABF",
      "#EE1233",
      "#40C057",
    ],
  };
  const [isLoading, setLoading] = useState(false);
  const [showConfetti, triggerConfetti] = useState(false);
  const onSubmit = (data, e) => {
    if (data.bot_field) return false;
    delete data.bot_field;
    triggerConfetti(true);
    setTimeout(() => {
      setLoading(true);
      axios
        .post("https://adream-mailing.herokuapp.com/send-wings", data)
        .then((res) => {
          setLoading(false);
          triggerConfetti(false);
          e.target.reset();
          navigate("/skrzydla-kontakt");
        })
        .catch((error) => {
          setLoading(false);
        });
    }, 1500);
  };
  return (
    <div className="wpcf7">
      {isLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="checkbox"
          name="bot_field"
          ref={register}
          className="bot-field"
        />
        <Input
          type="text"
          name="name"
          label="Imię"
          register={register}
          validation={{
            required: locales.required,
          }}
          errors={errors}
        />
        <Input
          type="number"
          name="phone"
          label="Telefon"
          register={register}
          validation={{ required: locales.required }}
          errors={errors}
        />
        <Input
          type="email"
          name="email"
          label="E-mail"
          register={register}
          validation={{
            required: locales.required,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
              message: locales.email_error,
            },
          }}
          errors={errors}
        />
        <div className="inp-group audytSelect">
          <select ref={register} name="areas" required>
            <option value="" disabled selected>
              Obszar wsparcia*
            </option>
            <option value="Strona internetowa">Strona internetowa</option>
            <option value="Sklep internetowy">Sklep internetowy</option>
            <option value="Kampania sprzedażowa">Kampania sprzedażowa</option>
            <option value="Szersze/inne działania">
              Szersze/inne działania
            </option>
          </select>
        </div>

        <Check
          register={register}
          name="term1"
          size="sm"
          validation={{ required: locales.required }}
          errors={errors}
          label="Wyrażam zgodę na przetwarzanie moich danych osobowych przez
          AAdream sp. z o.o. z siedzibą w Krakowie, ul.
          Chopina 18/3a, KRS: 0000517132 (Administrator danych) w celu
          udzielenia mi dodatkowych informacji handlowych z zakresu oferty
          Agencji. Podanie danych jest dobrowolne i umożliwia uzyskanie
          informacji handlowej."
        />
        <Check
          register={register}
          name="term2"
          size="sm"
          errors={errors}
          label="Wyrażam zgodę na udostępnienie moich danych osobowych przez Adream sp. z o.o., z siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000517132 jako administratora moich danych osobowych, podmiotowi: When Sp. z o. o. z siedzibą w Krakowie, ul. Chopina 18/3a, KRS: 0000683438 (kolejnemu Administratorowi danych), w celu udzielenia mi dodatkowych informacji handlowych z zakresu oferty When sp. z o.o. Podanie danych jest dobrowolne i umożliwia uzyskanie informacji handlowej.*"
        />
        <div className="btn_over_wrapper">
          <div className="btn_wrapper">
            <input
              type="submit"
              value="wyślij"
              className="wpcf7-form-control wpcf7-submit"
              onClick={() => {
                triggerValidation();
              }}
            />
            <Confetti active={showConfetti} config={config} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default WingsForm;
