import React, { Component } from "react";
import { Link } from "gatsby";
import { Layout } from "../components";
import locales from "../constants";
import scrollToElement from "scroll-to-element";
import { SvgWebsite, SvgShop, SvgBranding } from "../components/brief";
import WingsForm from "../components/WingsForm";

class Skrzydla extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.heroFade);
  }
  heroFade = () => {
    const heroFade = window.pageYOffset || document.documentElement.scrollTop;
    const header = document.getElementsByClassName("heroFade")[0];
    if (!header) return false;
    header.style.transform = "scale(" + (100 - heroFade / 50) / 98 + ")";
    header.style.opacity = 1 - heroFade / 40 / 50;
    header.style.transition = "0.2s";
  };

  render() {
    const { slug } = this.props.pageContext;

    return (
      <Layout
        seo={{
          title: "Skrzydła",
          href: slug,
          lang: "pl",
        }}
        header={{
          icons: "#fff",
          navClass: "wings",
        }}
      >
        <section className="wings_section_1">
          <img
            className="img-fluid"
            src={require("../assets/img/wings_top_back.jpg")}
            alt="Zensite"
          />
          <div className="wings_section_1--inner">
            <h3>A gdyby zamiast tarczy</h3>
            <h1>Twój biznes dostał skrzydeł?</h1>
          </div>
        </section>

        <section className="wings_section_2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h3>ADream doda Ci skrzydeł!</h3>
                <p>
                  Dla wielu branż obecny czas jest niezwykle trudny, a
                  ograniczenia bądź przestój w możliwości prowadzenia biznesu to
                  często walka o jego przetrwanie. Jesteśmy jednak pewni, że
                  nigdy nie wolno się poddawać, a często warto przejść do
                  kontrofensywy i zmierzać do celu wbrew przeciwnościom, aby z
                  kryzysu wyjść jeszcze mocniejszym. Porozmawiajmy o tym jak
                  dodać skrzydeł Twojej firmie!
                </p>
              </div>
              <div className="col-md-6">
                <div className="wings_section_2--form" id="wingsForm">
                  <WingsForm locales={locales["pl"]} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="wings_section_3">
          <div className="container">
            <h3>Oto jak możemy pomóc Twojemu biznesowi</h3>
            <p>
              Czasem potrzebna będzie realizująca cele strona internetowa
              zbierająca zapytania od kontrahentów, a innym razem sprawne
              wdrożenie sklepu internetowego. Zawsze konieczne będzie wsparcie w
              postaci działań sprowadzających wartościowy ruch na stronę czy
              sklep, które bez odwiedzających ich Klientów są bezwartościowe.
            </p>
            <div className="wings_section_3--row">
              <div className="row">
                <div className="col-sm-4">
                  <Link
                    to="/brief-strona-internetowa/"
                    className="brief_link brief_link__website"
                  >
                    <SvgWebsite />
                    <p>Skuteczna strona zbierająca leady</p>
                  </Link>
                </div>
                <div className="col-sm-4">
                  <Link
                    to="/brief-sklep"
                    className="brief_link brief_link__shop"
                  >
                    <SvgShop />
                    <p>Szybko wdrożony sklep internetowy</p>
                  </Link>
                </div>
                <div className="col-sm-4">
                  <Link
                    to="/brief-kompleksowa-kampania-reklamowa"
                    className="brief_link brief_link__branding"
                  >
                    <SvgBranding />
                    <p>
                      Kampania <br /> sprzedażowo / leadowa
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="wings_section_4">
          <div className="wings_section_4--wrapper">
            <div className="row no-gutters align-items-center">
              <div className="col-xl-6">
                <div className="wings_section_4--inner">
                  <h3>
                    Dlaczego warto trafić <br /> pod skrzydła ADream?
                  </h3>
                  <ul>
                    <li>
                      wychodzimy od założeń biznesowych - marketing to nie koszt
                      a inwestycja
                    </li>
                    <li>
                      zatrudniamy specjalistów ze wszystkich kluczowych obszarów
                      marketingu
                    </li>
                    <li>potrafimy zwiększyć sprzedaż nawet o 4318%</li>
                  </ul>
                  <ul className="wings_section_4--labels">
                    <li>
                      <a
                        href="/projekty/"
                        target="_blank"
                        className="btn_brief"
                      >
                        Projekty
                      </a>
                    </li>
                    <li>
                      <a href="/klienci/" target="_blank" className="btn_brief">
                        Klienci
                      </a>
                    </li>
                    <li>
                      <a
                        href="/referencje/"
                        target="_blank"
                        className="btn_brief"
                      >
                        Referencje
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="wings_section_4--thumb">
                  <h3>
                    Działamy partnersko i troszczymy się o biznes naszych
                    Klientów
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="wings_section_5">
          <div className="container">
            <h3>Dodaj skrzydeł swojemu biznesowi i wznieś się ponad kryzys</h3>
            <p>
              Niezależnie od rodzaju realizowanego projektu, zawsze skupiamy się
              na jego aspekcie biznesowym - na koniec dnia liczy się dla nas to
              czy nasze działania rozwijają biznesy naszych Klientów, bo tylko
              to jest gwarancją wartościowej i długotrwałej współpracy. W
              czasach ograniczeń dotyczących prowadzenia działalności jeszcze
              bardziej indywidualnie analizujemy każdy przypadek, planując
              działania najbardziej adekwatne do potrzeb danego przedsięwzięcia.
            </p>
            <button
              onClick={() => scrollToElement("#wingsForm", { offset: -100 })}
              className="btn_brief btn_brief--fill"
            >
              Porozmawiajmy
            </button>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Skrzydla;
